// export const SERVER_API_URL = 'https://ekyc-api.solidtech.vn/caisd-rest/';
// export const SERVER_API_URL = 'https://118.68.148.46:8443/CAisd/pdmweb.exe/';
// export const SERVER_API_URL = 'https://hypersd.telehouse.com.vn:8050/caisd-rest/';
export const SERVER_API_URL = 'https://telehouse.hypersd.vn:8050/caisd-rest/';

export const AUTH_USERNAME = 'telehouse';
export const AUTH_PASSWORD = 'Tiv@2021#$';

// export const SERVER_API_URL = 'https://telehouse.hypersd.vn/';
// export const AUTH_USERNAME = 'faceID';
// export const AUTH_PASSWORD = 'faceID@telehouse2023#';
