import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from './components/containers/ErrorBoundary';
import TheLayout from './components/containers/TheLayout';
import store from './store';


const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ToastContainer
          position={toast.POSITION.TOP_CENTER}
          className="toastify-container"
          toastClassName="toastify-toast"
        />
        <HashRouter>
          <TheLayout />
        </HashRouter>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
