import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../reducers';
import { ITicket, ITicketResponse, IUserByTicket, IUserByTicketResponse } from '../../../shared/models/groups.model';
import { IUser, IUserResponse } from '../../../shared/models/users.model';
import { IInitialState } from '../../../shared/utils/shared-interfaces';
import { getNewestTicket, getUserIdTicketFromResponse, getUserInfoFromResponse } from './helper';
import { attachImageToUser, getTicket, getUser, getUserListByTicket, getUserListDetail } from './users.api';

interface IUserInitialState extends IInitialState {
  user: IUser | null;
  fetchUserSuccess: boolean;
  attachImageToUserSuccess: boolean;

  newestTicket: ITicket | null;
  fetchTicketSuccess: boolean;

  listUserByTicket: IUserByTicket[];
  fetchUserByTicketSuccess: boolean;

  listUserDetail: Array<IUser>;
  fetchUserDetailSuccess: boolean;
}

const initialState: IUserInitialState = {
  attachImageToUserSuccess: false,
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  fetchUserSuccess: false,
  user: null,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  newestTicket: null,
  fetchTicketSuccess: false,
  listUserByTicket: [],
  fetchUserByTicketSuccess: false,
  listUserDetail: [],
  fetchUserDetailSuccess: false,
};

export const usersAdapter = createEntityAdapter<IUser>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'userSlice',
  initialState: usersAdapter.getInitialState({ initialState }),
  reducers: {
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.newestTicket = null;
      state.initialState.fetchTicketSuccess = false;
      state.initialState.attachImageToUserSuccess = false;
      state.initialState.fetchUserSuccess = false;
      state.initialState.user = null;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.listUserByTicket = [];
      state.initialState.fetchUserByTicketSuccess = false;
      state.initialState.listUserDetail = [];
      state.initialState.fetchUserDetailSuccess = false;
    },
    resetEntity(state) {
      state.initialState.attachImageToUserSuccess = false;
      state.initialState.fetchTicketSuccess = false;
      state.initialState.fetchUserSuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.fetchUserByTicketSuccess = false;
      state.initialState.fetchUserDetailSuccess = false;
    },
  },
  extraReducers: {
    [getUser.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IUserResponse>>) => {
      state.initialState.user = getUserInfoFromResponse(payload.data);
      state.initialState.fetchUserSuccess = true;
      state.initialState.loading = false;
    },
    [getUser.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload;
      state.initialState.loading = false;
      state.initialState.fetchUserSuccess = false;
    },
    [attachImageToUser.fulfilled.type]: (state, { payload }: PayloadAction<IUser>) => {
      state.initialState.attachImageToUserSuccess = true;
      state.initialState.loading = false;
    },
    [attachImageToUser.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.attachImageToUserSuccess = false;
    },
    [getTicket.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<ITicketResponse>>) => {
      state.initialState.newestTicket = getNewestTicket(payload.data);
      state.initialState.fetchTicketSuccess = true;
      state.initialState.loading = false;
    },
    [getTicket.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload;
      state.initialState.loading = false;
      state.initialState.fetchTicketSuccess = false;
    },
    [getUserListByTicket.fulfilled.type]: (state, { payload }: PayloadAction<AxiosResponse<IUserByTicketResponse>>) => {
      state.initialState.listUserByTicket = getUserIdTicketFromResponse(payload.data);
      state.initialState.fetchUserByTicketSuccess = true;
      state.initialState.loading = false;
    },
    [getUserListByTicket.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload;
      state.initialState.loading = false;
      state.initialState.fetchUserByTicketSuccess = false;
    },
    [getUserListDetail.fulfilled.type]: (state, { payload }: PayloadAction<Array<IUser>>) => {
      state.initialState.listUserDetail = payload;
      state.initialState.fetchUserDetailSuccess = true;
      state.initialState.loading = false;
    },
    [getUserListDetail.rejected.type]: (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload;
      state.initialState.loading = false;
      state.initialState.fetchUserDetailSuccess = false;
    },
  },
});

export const { fetching, resetAll, resetEntity } = actions;
export default reducer;

export const usersSelectors = usersAdapter.getSelectors<RootState>((state) => state.user);

const { selectById } = usersAdapter.getSelectors();
const getUserState = (rootState: RootState) => rootState.user;

export const selectEntityById = (id: string) => {
  return createSelector(getUserState, (state) => selectById(state, id));
};
