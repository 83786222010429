import { ITicket, ITicketResponse, IUserByTicket, IUserByTicketResponse } from '../../../shared/models/groups.model';
import { IUser, IUserByIdResponse, IUserResponse } from '../../../shared/models/users.model';

export const getUserInfoFromResponse = (response: IUserResponse): IUser | null => {
  try {
    const userId = response.collection_cnt.cnt['@id'];
    const address = response.collection_cnt.cnt.zcfg_requester_address;
    const email = response.collection_cnt.cnt.zcfg_requester_address_email;
    const comboName = response.collection_cnt.cnt.zfullname;
    const idCard = response.collection_cnt.cnt.zcfg_requester_id_passport;
    const phoneNumber = response.collection_cnt.cnt.zcfg_requester_phone_number;
    return {
      id: userId,
      address,
      email,
      comboName,
      idCard,
      phoneNumber,
    };
  } catch (e) {
    return null;
  }
};

const getUserDetailInfo = (response: IUserByIdResponse): IUser | null => {
  try {
    const userId = response.cnt['@id'];
    const address = response.cnt.zcfg_requester_address;
    const email = response.cnt.zcfg_requester_address_email;
    const comboName = response.cnt.zfullname;
    const idCard = response.cnt.zcfg_requester_id_passport;
    const phoneNumber = response.cnt.zcfg_requester_phone_number;
    return {
      id: userId,
      address,
      email,
      comboName,
      idCard,
      phoneNumber,
    };
  } catch (e) {
    return null;
  }
};

export const getListUserDetail = (response: IUserByIdResponse[]): IUser[] => {
  try {
    const userList: IUser[] | null = [];
    response.forEach((res) => {
      if (res.cnt.delete_flag['@REL_ATTR'] === 0) {
        const user = getUserDetailInfo(res);
        if (user) {
          userList.push(user);
        }
      }
    });
    return userList;
  } catch (e) {
    return [];
  }
};

const getTicketInfoFromResponse = (response: ITicketResponse): ITicket[] => {
  try {
    let ticketList: ITicket[] = [];
    if (Array.isArray(response.collection_cr.cr)) {
      ticketList = response.collection_cr.cr.map((ticket) => {
        const id = ticket['@id'];
        const attr = ticket['@REL_ATTR'];
        const commonName = ticket['@COMMON_NAME'];
        return {
          id,
          attr,
          commonName,
        };
      });
    } else if (response.collection_cr.cr) {
      const id = response.collection_cr.cr['@id'];
      const attr = response.collection_cr.cr['@REL_ATTR'];
      const commonName = response.collection_cr.cr['@COMMON_NAME'];
      ticketList.push({
        id,
        attr,
        commonName,
      });
    }
    return ticketList;
  } catch (e) {
    return [];
  }
};

export const getNewestTicket = (response: ITicketResponse): ITicket | null => {
  try {
    const ticketList: ITicket[] = getTicketInfoFromResponse(response);
    if (ticketList.length === 0) return null;
    const newestTicket = ticketList.reduce((prev, current) => {
      return prev.id > current.id ? prev : current;
    });
    return newestTicket;
  } catch (e) {
    return null;
  }
};

export const getUserIdTicketFromResponse = (response: IUserByTicketResponse): IUserByTicket[] => {
  try {
    let userList: IUserByTicket[] = [];
    if (Array.isArray(response.collection_zlrel_cr_cnt.zlrel_cr_cnt)) {
      userList = response.collection_zlrel_cr_cnt.zlrel_cr_cnt.map((ticket) => {
        const id = ticket.cnt['@id'];
        const attr = ticket.cnt['@REL_ATTR'];
        const commonName = ticket.cnt['@COMMON_NAME'];
        return {
          id,
          attr,
          commonName,
        };
      });
    } else if (response.collection_zlrel_cr_cnt.zlrel_cr_cnt) {
      const id = response.collection_zlrel_cr_cnt.zlrel_cr_cnt.cnt['@id'];
      const attr = response.collection_zlrel_cr_cnt.zlrel_cr_cnt.cnt['@REL_ATTR'];
      const commonName = response.collection_zlrel_cr_cnt.zlrel_cr_cnt.cnt['@COMMON_NAME'];
      userList.push({
        id,
        attr,
        commonName,
      });
    }

    return userList;
  } catch (e) {
    return [];
  }
};
